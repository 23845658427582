import { classNames } from '@axo/shared/util/dom';
import { getFormattedAmountUI } from '@axo/shared/util/formatters';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { DebtsInfo, TDebtsInfoProps } from './components/DebtsInfo/DebtsInfo';
import LoanOverviewBlock from './components/LoanOverviewBlock/LoanOverviewBlock';
import { getDisclaimerText } from './components/LoanOverviewBlock/utils/getDisclaimerText';
import {
  ExtendedLoanOverviewContextProvider,
  useExtendedLoanOverviewContext,
} from './context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';
import styles from './ExtendedLoanOverview.module.scss';
import type { TLoanOverviewProps } from './ExtendedLoanOverview.types';
import Chat from './icons/Chat';
import { useDebtRefinanceAnalytics } from './useDebtRefinanceAnalytics';

const cashAmountSliderConfig = {
  trackHeight: 8,
};

const defaultStep = 250;

export const ExtendedLoanOverviewInternal = ({
  labels,
  debtsInfoProps,
  isLoanDurationVisible = true,
  isDebtInfoButtonVisible,
  refinanceableDebtsExtraProps,
  isEditButtonsVisible = true,
  cashBlockProps = {},
}: {
  labels: TLoanOverviewProps['labels'];
  debtsInfoProps?: Omit<TDebtsInfoProps, 'onClose'>;
  isLoanDurationVisible?: TLoanOverviewProps['isLoanDurationVisible'];
  isDebtInfoButtonVisible?: TLoanOverviewProps['isDebtInfoButtonVisible'];
  refinanceableDebtsExtraProps?: TLoanOverviewProps['refinanceableDebtsExtraProps'];
  isEditButtonsVisible?: TLoanOverviewProps['isEditButtonsVisible'];
  cashBlockProps?: TLoanOverviewProps['cashBlockProps'];
}) => {
  const [isDebtDetailsModalVisible, setIsDebtDetailsModalVisible] =
    useState(false);

  const {
    editMode,
    setEditMode,
    refinanceAmount,
    monthlyPayment,
    cashAmount,
    loanDuration,
    isWarningBoxVisible,
    maxLoanAmount,
    currency,
    totalLoanAmount,
    refinanceableDebts,
    maxRefinanceableDebtsValues,
    onLoanDurationChange,
    onDebtValueChange,
    onCashAmountChange,
    onRefinanceableDebtCheckboxChange,
  } = useExtendedLoanOverviewContext();

  useDebtRefinanceAnalytics();

  const refinanceAmountDetails: ComponentProps<
    typeof LoanOverviewBlock
  >['details'] = useMemo(
    () =>
      refinanceableDebts.map(
        (
          {
            label,
            value,
            key,
            isChecked,
            step,
            interestRate,
            type,
            debtDescription,
          },
          idx
        ) => {
          const descriptionSuffix =
            type === 'cc' ? labels?.creditCardLabel : labels?.consumerLoanLabel;
          const description = `${descriptionSuffix} ${interestRate?.toString()}% ${
            labels?.interestRateLabel?.toLowerCase() ?? ''
          }`;

          return {
            label,
            value,
            description: interestRate
              ? description
              : debtDescription ?? undefined,
            maxValue: maxRefinanceableDebtsValues[idx],
            onValueChange: (newValue) =>
              onDebtValueChange({ debtType: key, newValue }),
            onCheckboxChange: (newValue) =>
              onRefinanceableDebtCheckboxChange({ debtType: key, newValue }),
            step: step ?? defaultStep,
            isChecked: !!isChecked,
          };
        }
      ),

    [
      refinanceableDebts,
      maxRefinanceableDebtsValues,
      onDebtValueChange,
      onRefinanceableDebtCheckboxChange,
      labels?.creditCardLabel,
      labels?.consumerLoanLabel,
      labels?.interestRateLabel,
    ]
  );

  const monthlyPaymentLabelSuffix = useMemo(() => {
    const monthLabel = labels?.monthLabel ?? 'month';

    return currency ? `${currency} ${monthLabel}` : monthLabel;
  }, [currency, labels?.monthLabel]);

  const handleOnInfoButtonClick = useCallback(
    () => setIsDebtDetailsModalVisible(true),
    []
  );

  const handleOnInfoModalClose = useCallback(
    () => setIsDebtDetailsModalVisible(false),
    []
  );

  const loanAmountDescription = useMemo(() => {
    const totalUserPayment = monthlyPayment * loanDuration * 12;
    const costForUser = totalUserPayment - totalLoanAmount;

    const disclaimerText = getDisclaimerText({
      labels,
      loanDuration,
      totalUserPayment,
      totalLoanAmount,
      costForUser,
    });

    if (!disclaimerText) return null;

    return <div className={styles.loanAmountDescription}>{disclaimerText}</div>;
  }, [monthlyPayment, loanDuration, totalLoanAmount, labels]);

  const loanAmountAndMonthlyPaymentTitle = useMemo(
    () => (
      <div className={styles.loanAmountAndMonthlyPayment}>
        <span className={styles.text}>
          {labels?.totalLoanAmountLabel ?? 'Total loan amount'}
        </span>
        {labels?.monthlyPaymentLabel && (
          <span className={styles.text}>{labels?.monthlyPaymentLabel}</span>
        )}
      </div>
    ),

    [labels?.totalLoanAmountLabel, labels?.monthlyPaymentLabel]
  );

  const loanAmountAndMonthlyPaymentValueAsNode = useMemo(
    () => (
      <div
        className={classNames(
          styles.loanAmountAndMonthlyPayment,
          styles.loanAmountAndMonthlyPaymentValues
        )}
      >
        <span
          className={classNames(styles.text, styles.textRight)}
        >{`${getFormattedAmountUI(totalLoanAmount)} ${currency}`}</span>
        {labels?.monthlyPaymentLabel && (
          <span
            className={classNames(styles.text, styles.textRight)}
          >{`${monthlyPayment} ${monthlyPaymentLabelSuffix}`}</span>
        )}
      </div>
    ),
    [
      totalLoanAmount,
      monthlyPayment,
      monthlyPaymentLabelSuffix,
      currency,
      labels,
    ]
  );

  return (
    <>
      <div className={styles.extendedLoanOverview}>
        {labels?.title && <h3 className={styles.title}>{labels.title}</h3>}
        {refinanceAmountDetails?.length ? (
          <LoanOverviewBlock
            type="refinanceAmount"
            title={labels?.refinanceAmountLabel ?? 'Refinance amount'}
            value={refinanceAmount}
            labelSuffix={currency}
            details={refinanceAmountDetails}
            isEditable={
              isEditButtonsVisible && refinanceAmountDetails.length > 0
            }
            isEditMode={editMode === 'refinanceAmount'}
            onEnableEditModeClick={setEditMode}
            isInfoButtonVisible={isDebtInfoButtonVisible}
            onInfoButtonClick={handleOnInfoButtonClick}
            {...refinanceableDebtsExtraProps}
          />
        ) : null}
        <LoanOverviewBlock
          type="cashAmount"
          title={labels?.cashAmountLabel ?? 'Cash amount'}
          value={cashAmount}
          labelSuffix={currency}
          onChange={onCashAmountChange}
          maxValue={maxLoanAmount}
          minValue={0}
          step={500}
          isEditMode={editMode === 'cashAmount'}
          onEnableEditModeClick={setEditMode}
          sliderConfig={cashAmountSliderConfig}
          isEditable={isEditButtonsVisible}
          {...cashBlockProps}
        />
        <div className={styles.divider} />
        <LoanOverviewBlock
          type="loanAmount"
          title={loanAmountAndMonthlyPaymentTitle}
          valueAsNode={loanAmountAndMonthlyPaymentValueAsNode}
          isEditable={false}
          labelSuffix={currency}
          description={loanAmountDescription}
          theme="blue"
        />

        {isLoanDurationVisible && (
          <LoanOverviewBlock
            type="loanDuration"
            title={labels?.loanDurationLabel ?? 'Loan duration'}
            value={loanDuration}
            labelSuffix={labels?.yearsLabel ?? 'years'}
            editType="steppedNumberInput"
            onChange={onLoanDurationChange}
            onEnableEditModeClick={setEditMode}
            isEditMode={editMode === 'loanDuration'}
            maxValue={15}
            minValue={5}
            className={classNames(
              styles['loan-duration'],
              editMode === 'loanDuration' && styles['loan-duration--edit']
            )}
            isWarning={isWarningBoxVisible}
            isEditable={isEditButtonsVisible}
          />
        )}

        {labels?.warningMessage && isWarningBoxVisible && (
          <div className={styles.warning}>
            <Chat />
            <span className={styles.text}>{labels?.warningMessage}</span>
          </div>
        )}
      </div>
      {isDebtDetailsModalVisible && debtsInfoProps && (
        <DebtsInfo onClose={handleOnInfoModalClose} {...debtsInfoProps} />
      )}
    </>
  );
};

export const ExtendedLoanOverview = (props: TLoanOverviewProps) => (
  <ExtendedLoanOverviewContextProvider {...props}>
    <ExtendedLoanOverviewInternal
      labels={props.labels}
      debtsInfoProps={props.debtInfoProps}
      isLoanDurationVisible={props.isLoanDurationVisible}
      isDebtInfoButtonVisible={props.isDebtInfoButtonVisible}
      refinanceableDebtsExtraProps={props.refinanceableDebtsExtraProps}
      isEditButtonsVisible={props.isEditButtonsVisible}
      cashBlockProps={props.cashBlockProps}
    />
  </ExtendedLoanOverviewContextProvider>
);
