import { ReactNode } from 'react';

import { Icon } from '@axo/ui-core/components/Icon';
import styles from './AuthenticationCard.module.scss';

type TAuthenticationCardProps = {
  title: string;
  description: string;
  bullets?: string[];
  extra?: ReactNode;
  children: ReactNode;
};

export const AuthenticationCard = ({
  title,
  description,
  bullets,
  extra,
  children,
}: TAuthenticationCardProps) => {
  return (
    <div className={styles.authenticationCard}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>
      {bullets?.length && (
        <ul className={styles.bullets}>
          {bullets.map((bullet) => (
            <li>
              <Icon name="badge-check-solid" className={styles.icon} />
              <span className={styles.text}>{bullet}</span>
            </li>
          ))}
        </ul>
      )}
      {extra && <p className={styles.extra}>{extra}</p>}
      <div className={styles.authentication}>{children}</div>
    </div>
  );
};
